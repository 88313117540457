// Required
@import "ember-bootstrap/functions";
/*
* Orson http://en.orson.io , autreplanete http://www.autreplanete.com/
*
**/


// Brand Colors
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$grays: (100: $gray-100, 200: $gray-200, 300: $gray-300, 400: $gray-400, 500: $gray-500, 600: $gray-600, 700: $gray-700, 800: $gray-800, 900: $gray-900);
$theme-colors: (primary: $red, secondary: $gray-600, success: $green, info: $cyan, warning: $yellow, danger: $red, light: $gray-100, dark: $gray-800);
$colors: (blue: $blue, indigo: $indigo, purple: $purple, pink: $pink, red: $red, orange: $orange, yellow: $yellow, green: $green, teal: $teal, cyan: $cyan, white: $white, gray: $gray-600,gray-dark: $gray-800);
$theme-color-interval: 8%;
@import "ember-bootstrap/variables";
@import "ember-bootstrap/mixins";

// Optional - Bootstrapping/Resetting
@import "ember-bootstrap/root";
@import "ember-bootstrap/print";
@import "ember-bootstrap/reboot";

// Optional - Everything else
@import "ember-bootstrap/type";
@import "ember-bootstrap/buttons";

@import "ember-bootstrap/bootstrap";

@import url(https://fonts.googleapis.com/css?family=Rubik);

body {
  font-family: 'Rubik', sans-serif;
}

#snow-canvas {
  pointer-events: none;
  position: fixed;
  top: 0;
  opacity: .5;
}
